import instance from "./http";
import domain from "./base";
import QS from "qs";
import axios from "axios";
const post = (url, params) => {
  let parames = {
    accessToken: "By2ZuFl5rz2VxHlJ1",
    // accessToken: "",
    // localStorage.getItem("accessToken") != undefined
    // ? localStorage.getItem("accessToken")
    // : "",
    user_id: 45880,
    // user_id: "",
    // localStorage.getItem("userId") != undefined
    //   ? localStorage.getItem("userId")
    //   : "",
    token: "78acadca6479e6d613260082e1abb902",
    // token: "",
    // localStorage.getItem("token") != undefined
    //   ? localStorage.getItem("token")
    //   : "",
    requestParams: params,
  };
  // let parames = params;
  return new Promise((resolve, reject) => {
    instance
      .post(`${domain.formal}${url}`, QS.stringify(parames))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        // this.$toast(JSON.parse(err.data).msg)
        // console.log(JSON.parse(err.data).msg)
        reject(err);
      });
  });
};
export default {
  post,
};
