import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
    meta: {
      index: 0,
    },
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/index.vue"),
    meta: {
      index: 0,
    },
  },
  {
    path: "/explain",
    name: "explain",
    component: () => import("../views/explain.vue"),
    meta: {
      index: 1,
    },
  },
  {
    path: "/msg",
    name: "msg",
    component: () => import("../views/msg.vue"),
    meta: {
      index: 1,
    },
  },
  {
    path: "/resume",
    name: "resume",
    component: () => import("../views/resume.vue"),
    meta: {
      index: 2,
    },
  },
  {
    path: "/resume1",
    name: "resume1",
    component: () => import("../views/resume1.vue"),
    meta: {
      index: 3,
    },
  },
  {
    path: "/resume2",
    name: "resume2",
    component: () => import("../views/resume2.vue"),
    meta: {
      index: 4,
    },
  },
  {
    path: "/record",
    name: "record",
    component: () => import("../views/record.vue"),
    meta: {
      index: 5,
    },
  },
  {
    path: "/zw",
    name: "zw",
    component: () => import("../views/zw.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/intention",
    name: "intention",
    component: () => import("../views/intention.vue"),
    meta: {
      index: 3,
    },
  },
  {
    path: "/station",
    name: "station",
    component: () => import("../views/station.vue"),
    meta: {
      index: 3,
    },
  },
  {
    path: "/advantage",
    name: "advantage",
    component: () => import("../views/advantage.vue"),
    meta: {
      index: 3,
    },
  },
  // 广告
  {
    path: "/ending",
    name: "ending",
    component: () => import("../views/ending.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import("../views/preview.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/safeguard",
    name: "safeguard",
    component: () => import("../views/safeguard.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/makemoney",
    name: "makemoney",
    component: () => import("../views/makemoney.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/collaborate",
    name: "collaborate",
    component: () => import("../views/collaborate.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/school",
    name: "school",
    component: () => import("../views/school.vue"),
    meta: {
      index: 6,
    },
  },
  {
    path: "/school2",
    name: "school2",
    component: () => import("../views/school2.vue"),
    meta: {
      index: 6,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
