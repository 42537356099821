/*
 * @Author: your name
 * @Date: 2021-01-28 18:44:37
 * @LastEditTime: 2021-05-25 11:22:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\util\base.js
 */
/**
 * 接口域名的管理
 */
const base = {
  formal: "https://api.luobobeidiao.cn",
  urlc:"https://client.luobobeidiao.cn", //c端域名
  test: "https://api.luobobeidiao.cn",
  url: "https://copm.luobobeidiao.cn", //线上访问地址（非接口地址）


  // formal: "https://api.luobobeidiao.cn",
  // urlc:"https://client.luobobeidiao.cn", //c端域名
  // test: "https://api.luobobeidiao.cn",
  // url: "https://copm.luobobeidiao.cn", //线上访问地址（非接口地址）
};

export default base;
