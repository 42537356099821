<!--
 * @Author: your name
 * @Date: 2021-01-28 18:44:37
 * @LastEditTime: 2021-04-27 20:09:00
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\App.vue
-->
<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: "",
      name: "",
    };
  },

  watch: {
    $route(to, from) {
      // console.log(to);aa
      // this.name = localStorage.getItem("to");
      // console.log(this.name, from.name);
      // if (this.name == to.name) {
      //   console.log("左边");
      //   this.transitionName = "slide-right";
      // } else if (to.name == "index") {
      //   this.transitionName = "";
      // } else {
      //   console.log("右边");
      //   this.transitionName = "slide-left";
      // }
      // localStorage.setItem("to", from.name);
      if (to.meta.index == 0) {
        this.transitionName = "";
      } else if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}
#app{
  overflow: hidden;
}
.ss{
  white-space: nowrap;
}
</style>
